@media screen and (max-width: 1450px) {
  :root {
    --main: #0f2938;
    /* --main: white; */
    --maintrans: #0f2938;
    --white: #fffefd;
    --light: #5886a1;
    --dark: #012236;
    --darktrans: #102c3c;
    --black: #372b47;
    --grey: #e0dfdf;
    --dark-grey: #9a9898;
    --accent: #48c6c5;
    --green: #00cc83;
    --red: #c25556;
    --lightaccent: #a5eded;
    font-size: 50%;
  }
}

@media screen and (min-width: 1450px) {
  :root {
    --main: #0f2938;
    /* --main: white; */
    --maintrans: #0f2938;
    --white: #fffefd;
    --light: #5886a1;
    --dark: #012236;
    --darktrans: #102c3c;
    --black: #372b47;
    --grey: #e0dfdf;
    --dark-grey: #9a9898;
    --accent: #48c6c5;
    --green: #00cc83;
    --red: #c25556;
    --lightaccent: #a5eded;
    font-size: 62.5%;
  }
}

.App {
  text-align: center;
  height: 100vh;
  background-color: var(--white);
  font-family: "Poppins";
}

p {
  font-size: 1.4rem !important;
}

a {
  text-decoration: none;
  color: var(--white);
}

a:visited {
  color: var(--white);
}

.notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 10%;
  left: 35%;
  width: 30%;
  height: 5%;
  /* background-image: linear-gradient(to right, #00cc83, #00cc83, #00cc83); */
  border-radius: 1rem;
  /* border: .4rem solid #15b07a; */
  z-index: 100;
  color: var(--white);
  font-family: "Poppins";
  /* padding: rem 1rem; */
  font-weight: bold;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.466);
  /* animation: notif-animate 2s forwards 0s 1; */
  z-index: 10000;
  font-size: 1.4rem;
}

.notification-hidden {
  display: none;
}

.ok-btn {
  width: 10%;
  /* font-weight: bold; */
  font-size: 1.4rem;
  border-left: 0.1rem solid rgba(255, 255, 255, 0.509);
  /* padding-left: 1rem; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -1rem;
  z-index: 99;
}

.notif-icon {
  width: 10%;
  font-size: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.not-msg {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

@keyframes notif-animate {
  0% {
    background-image: linear-gradient(to right, white 0%, white 100%);
  }
  33% {
    background-image: linear-gradient(
      to right,
      #00cc83 0%,
      #00cc83 33%,
      white 45%,
      white 100%
    );
  }
  66% {
    background-image: linear-gradient(
      to right,
      #00cc83 0%,
      #00cc83 66%,
      white 78%,
      white 100%
    );
  }
  100% {
    background-image: linear-gradient(to right, #00cc83 0%, #00cc83 100%);
  }
}

/* width */
::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  background-color: var(--main);
  border-radius: 2rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.3rem var(--dark);
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--accent);
  border-radius: 2rem;
}

select {
  outline-style: solid;
  outline-width: 0.1rem;
  outline-color: black;
  padding-left: 1rem;
  color: black;
}

select:focus {
  outline-style: solid;
  outline-width: 0.1rem;
  color: black;
  outline-color: black;
}

.loading {
  z-index: 10000;
  background-color: #eee;
  top: 47%;
  left: 48.5%;
  width: 2%;
  aspect-ratio: 1/1;
  position: absolute;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  padding: 1.5rem;
  animation: de 8s infinite alternate;
}

.loading-inner {
  margin: 0 auto;
  animation: design 8s infinite alternate;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* align-items: flex-end; */
}

.small-e-line {
  width: 75%;
  height: 1.5rem;
  background-color: white;
  animation: colors 8s infinite alternate;
  /* border-radius: .5rem; */
}

@keyframes design {
  0% {
    background: #f45657;
    border-radius: 0 50% 50% 0;
    transform: rotate(0deg);
  }
  5% {
    background: #f45657;
    border-radius: 0 50% 50% 0;
    transform: rotate(0deg);
  }
  16% {
    background: #ff965d;
    border-radius: 0 0 0 0;
    transform: rotate(0deg);
  }
  21% {
    background: #ff965d;
    border-radius: 0 0 0 0;
    transform: rotate(0deg);
  }
  32% {
    background: #ffc233;
    border-radius: 0 50% 50% 0;
    transform: rotate(0deg);
  }
  37% {
    background: #ffc233;
    border-radius: 0 50% 50% 0;
    transform: rotate(0deg);
  }
  48% {
    background: #51d8b0;
    border-radius: 0 0 0 0;
    transform: rotate(360deg);
  }
  53% {
    background: #51d8b0;
    border-radius: 0 0 0 0;
    transform: rotate(360deg);
  }
  64% {
    background: #2873ff;
    border-radius: 0 50% 50% 0;
    transform: rotate(360deg);
  }
  69% {
    background: #2873ff;
    border-radius: 0 50% 50% 0%;
    transform: rotate(360deg);
  }
  80% {
    background: #843fff;
    border-radius: 0 0 0 0;
    transform: rotate(720deg);
  }
  85% {
    background: #843fff;
    border-radius: 0 0 0 0;
    transform: rotate(720deg);
  }
  100% {
    background: #843fff;
    border-radius: 0 50% 50% 0;
    transform: rotate(720deg);
  }
}

@keyframes colors {
  0% {
    background: transparent;
    border-radius: 0 50% 50% 0;
  }
  5% {
    background: transparent;
    border-radius: 0 50% 50% 0;
  }
  16% {
    background: #eee;
    border-radius: 0 0 0 0;
    transform: rotate(180deg);
  }
  21% {
    background: #eee;
    border-radius: 0 0 0 0;
    transform: rotate(180deg);
  }
  32% {
    background: transparent;
    border-radius: 0 50% 50% 0;
    transform: rotate(0deg);
  }
  37% {
    background: transparent;
    border-radius: 0 50% 50% 0;
    transform: rotate(0deg);
  }
  48% {
    background: #eee;
    border-radius: 0 0 0 0;
    transform: rotate(360deg);
  }
  53% {
    background: #eee;
    border-radius: 0 0 0 0;
    transform: rotate(360deg);
  }
  64% {
    background: transparent;
    border-radius: 0 50% 50% 0;
    transform: rotate(360deg);
  }
  69% {
    background: transparent;
    border-radius: 0 50% 50% 0%;
    transform: rotate(360deg);
  }
  80% {
    background: #eee;
    border-radius: 0 0 0 0;
    transform: rotate(540deg);
  }
  85% {
    background: #eee;
    border-radius: 0 0 0 0;
    transform: rotate(540deg);
  }
  100% {
    background: transparent;
    border-radius: 0 50% 50% 0;
    transform: rotate(540deg);
  }
}

@keyframes de {
  0% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0;
    padding-right: 1.5rem;
  }
  5% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0;
    padding-right: 1.5rem;
  }
  16% {
    /* background: #eee; */

    border-radius: 0 0 0 0;
    padding-right: 0;

    /* transform: rotate(180deg); */
  }
  21% {
    /* background: #eee; */
    border-radius: 0 0 0 0;
    padding-right: 0;

    /* transform: rotate(180deg); */
  }
  32% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0;
    padding-right: 1.5rem;

    /* transform: rotate(0deg); */
  }
  37% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0;
    padding-right: 1.5rem;

    /* transform: rotate(0deg); */
  }
  48% {
    /* background: #eee; */
    border-radius: 0 0 0 0;
    padding-right: 0;

    /* transform: rotate(360deg); */
  }
  53% {
    /* background: #eee; */
    border-radius: 0 0 0 0;
    padding-right: 0;

    /* transform: rotate(360deg); */
  }
  64% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0;
    padding-right: 1.5rem;

    /* transform: rotate(360deg); */
  }
  69% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0%;
    padding-right: 1.5rem;

    /* transform: rotate(360deg); */
  }
  80% {
    /* background: #eee; */
    border-radius: 0 0 0 0;
    padding-right: 0;

    /* transform: rotate(540deg); */
  }
  85% {
    /* background: #eee; */
    border-radius: 0 0 0 0;
    padding-right: 0;

    /* transform: rotate(540deg); */
  }
  100% {
    /* background: transparent; */
    border-radius: 0 50% 50% 0;
    padding-right: 1.5rem;

    /* transform: rotate(540deg); */
  }
}

.loading-bar {
  top: 8vh;
  /* width: 100%; */
  height: 0.5rem;
  position: absolute;
  background-color: #00cc83;
  animation: load 3s infinite;
  z-index: 10000;
}

@keyframes load {
  0% {
    width: 5%;
  }
  10% {
    width: 10%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 90%;
  }
}

.inactive {
  pointer-events: none;
}
