.r-e-text-area > textarea {
  font-weight: normal;
  font-size: 1.3rem;
  text-align: center;
  margin: auto;
}

.role-preview {
  position: fixed;
  top: 35%;
  bottom: 35%;
  left: 15%;
  right: 15%;
  background-color: white;
  border: 0.3rem solid black;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  z-index: 100;
}
