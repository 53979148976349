.p-menu {
  /* margin: .2rem 0rem; */
}

.selected-menu-head {
  background-color: var(--accent);
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.p-menu-sidebar {
  background-color: var(--main);
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.466);
  z-index: 10;
}

.f-p-dtl-head {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin-top: 1rem;
  height: 5%;
  align-items: center;
  font-size: 1.8rem;
  gap: 2rem;
}

.u-d-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 0rem;
}

.u-menu {
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 100%;
  align-items: center;
  background-color: var(--white);
  /* gap: 2rem; */
  /* padding-top: 2rem; */
  transition: 1s;
}

.u-menu-head {
  /* border: 0.1rem solid var(--main); */
  margin-top: 2rem;
  display: flex;
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
  flex-direction: column;
  color: var(--dark);
}

.u-menu-head > div {
  padding: 1rem 2rem;
}

.u-menu-head > div > i {
  font-size: 3rem;
}

.u-menu-head:hover > .unselected-menu-head {
  background-color: var(--grey);
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.unselected-menu-head {
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.u-menu-part {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-bottom: 1rem;
  background-color: var(--main);
  overflow-y: auto;
}

.u-menu-i-head {
  padding-left: 2rem;
  background-color: var(--main);
  color: var(--white);
}

.u-menu-i {
  background-color: var(--grey);
  color: var(--main);
  border-bottom: 0.1rem solid var(--main);
  padding: 0.2rem 0rem 0.2rem 4rem;
  cursor: pointer;
}

.p-not {
  background-color: yellow;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  position: absolute;
  top: 35%;
  right: 5%;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.f-dtl-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-color: var(--white);
  width: 90%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.f-dtl-head {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 1rem;
  height: 5%;
  align-items: center;
  font-size: 1.8rem;
}

.f-sub-dtl-head {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  /* width: 10%; */
  /* margin-top: 1rem; */
  /* height: 5%; */
  align-items: center;
  font-size: 1.6rem;
}

.i-btn {
  display: flex;
  align-items: center;
  background-color: var(--green);
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.466);
  font-size: 1.6rem;
  cursor: pointer;
}

.f-i-btn {
  display: flex;
  align-items: center;
  background-color: var(--green);
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.466);
  font-size: 1.6rem;
  font-family: "Poppins";
  cursor: pointer;
  border-radius: 0rem;
  text-transform: "none" !important;
  border: none;
}

.f-dtl {
  display: flex;
  width: 95%;
}

.f-table {
  display: flex;
  width: 100%;
  justify-content: center;
  /* margin-top: 1rem; */
}

.f-dept-table {
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: center;
  margin-top: 1rem;
}

.MuiPaper-elevation {
  box-shadow: 0rem 0rem 0rem white;
  width: 98%;
  box-shadow: 0rem 0rem 0rem white;
  shadows: none;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.MuiTableSortLabel-icon {
  stroke: white;
  stroke-width: 2;
}

.MuiMenu-paper {
  width: auto;
}

table {
  margin-bottom: 1rem;
}

.c-actions {
  text-align: center;
}

.a-icon > path {
  stroke: var(--accent);
  fill: var(--accent);
  stroke-width: 0.1rem;
}

.btns {
  display: flex;
  gap: 1rem;
}

.btn-controls {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

.create-btn {
  padding: 0.5rem 2rem;
  background-color: var(--green);
  color: white;
  margin-top: 1rem;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  font-size: 1.6rem;
}

.create-btn-n {
  padding: 0.5rem 2rem;
  background-color: var(--green);
  color: white;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  font-size: 1.6rem;
}

.create-btn-b {
  padding: 0.5rem 2rem;
  background-color: dodgerblue;
  color: white;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  font-size: 1.6rem;
}

.create-btn-bl {
  padding: 0.5rem 2rem;
  background-color: black;
  color: white;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  font-size: 1.6rem;
}

.margin-top-10 {
  margin-top: 1rem;
}

.add-shift-btn {
  padding: 0.5rem 2rem;
  background-color: var(--green);
  color: black;
  margin: 1rem 0rem;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  text-align: center;
  font-size: 1.4rem;
}

.created-row {
  display: flex;
  gap: 0.5rem;
}

.creation-cell {
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  height: 5rem;
}

.creation-cell-auto {
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  /* animation: tinLeftIn 1s 1; */
}

.cell-name {
  font-size: 1.6rem;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.search-control {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9%;
}

.cell-control {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cell-control > div {
  width: 98%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-control > input {
  width: 98%;
  font-weight: normal;
}

.cell-control > input {
  width: 98%;
  font-weight: normal;
}

.r-r > input[type="radio"] {
  margin: auto 0.5rem;
  width: 100%;
}

.r-r {
  display: flex;
  width: 20%;
}

.r-label {
  display: flex;
  /* width: 90%; */
}
.cell-control > input:disabled {
  background-color: var(--white);
}

.cell-control > input[type="file"] {
  text-align-last: left;
  direction: ltr;
  display: inline-block;
  content: "Attach";
  border-radius: none;
  line-height: 4rem;
  background-color: var(--white);
}

.cell-control > input[type="checkbox"] {
  height: 2rem;
}

input::file-selector-button {
  background-color: dodgerblue;
  color: white;
  height: 100%;
  float: left;
  font-family: "Poppins";
  cursor: pointer;
  border-radius: 1.2rem 0rem 0rem 1.2rem;
  outline: 0.3rem outset dodgerblue;
  outline-offset: -0.3rem;
}

.cell-control > select {
  width: 98%;
  font-weight: normal;
}

.cell-control > textarea {
  width: 98%;
  background-color: var(--white);
  font-weight: normal;
  /* height: 10%; */
}

.cell-control > textarea::placeholder {
  text-align: center;
  line-height: 0rem;
  font-weight: normal;
}

.r-group {
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

.f-btn {
  width: 90%;
  height: 90%;
  font-size: 1.6rem;
  font-family: Poppins;
  border-radius: 1rem;
  cursor: pointer;
}

.btn-cell-control {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.transparent-btn {
  pointer-events: none;
}

.created-container {
  content: "";
  width: 100%;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 0.5rem;
}

.search-bar {
  display: flex;
  background-color: #ededed;
  width: 70%;
  height: 5rem;
  border-radius: 2rem;
}

.f-sh-head {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.f-sh-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 2.5%;
}

.f-sh-dtl-container {
  flex: 0.9;
  width: 90%;
  height: 8rem;
  align-items: center;
  justify-content: center;
  margin-top: 2.5%;
  overflow-y: auto;
  padding: 2rem 1rem;
}

.site-card {
  width: 100%;
  height: 5rem;
  margin-bottom: 0.5rem;
  display: flex;
  background-color: var(--maintrans);
  color: white;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.366);
}

.site-icon {
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent);
  font-size: 1.4rem;
}

.site-name {
  height: 100%;
  width: 90%;
  padding-left: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
}

.btn {
  width: 100%;
  height: 70%;
  /* font-size: 1.5rem !important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0.1rem solid var(--main);
  background-color: var(--accent);
  padding: 0.5rem 0rem;
  font-size: 1.6rem;
}

.h-items {
  display: flex;
  /* width: 100%; */
  gap: 2rem;
  padding: 1rem 2rem;
  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.466);
  font-size: 1.4rem;
}

.h-container {
  position: fixed;
  top: 25%;
  /* bottom: 25%; */
  left: 15%;
  right: 15%;
  background-color: white;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.h-m-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(50vh - 4rem);
}

.viz-preview-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  gap: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

.viz-preview-details > .viz-name {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.viz-preview-details > .viz-filter > select {
  font-size: 1.2rem;
  /* height: 100%; */
  height: 3rem;
  margin-left: 1rem;
}

.grow {
  flex-grow: 1;
}

.cancel-btn {
  padding: 0.5rem 2rem;
  background-color: var(--red);
  color: white;
  margin-top: 1rem;
  cursor: pointer;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  font-size: 1.6rem;
}

.pp-tt {
  padding: 1rem 2rem;
  background-color: white;
  text-wrap: nowrap;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  z-index: 10000;
}

.tt-msg {
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  background-color: var(--grey);
}

.tt-line {
  display: flex;
}

.tt-head {
  font-weight: bold;
}

.d-icon {
  cursor: pointer;
}

.google-visualization-tooltip {
  opacity: 0 !important;
  /* max-width: 200px !important; */
}
.google-visualization-tooltip[clone] {
  opacity: 1 !important;
  pointer-events: all !important;
}

#tt-id {
  opacity: 0;
  font-size: 0.5rem;
}
