.dept-preview {
  position: fixed;
  top: 25%;
  bottom: 25%;
  left: 15%;
  right: 15%;
  background-color: white;
  border: 0.3rem solid black;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.dept-node {
  background-color: var(--accent);
  font-family: "Poppins";
  outline: 0.3rem outset var(--dark);
  outline-offset: -0.3rem;
  cursor: pointer;
}

.dept-node p {
  fill: var(--white);
  font-size: 2rem;
  text-align: center;
  padding: 0.5rem 2rem;
}

.nodes li {
  color: var(--dark);
  font-size: 1.5rem;
}

.tree-org {
  overflow-x: auto;
}
