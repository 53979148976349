.f-in-table {
  display: flex;
  width: 100%;
  justify-content: center;
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.f-in-table-ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  gap: 2rem;
  padding-bottom: 3rem;
}

.ch-line {
  width: 95%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #edededa5;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  border-radius: 0.5rem;
}

.c-line {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #edededa5;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  border-radius: 0.5rem;
}

.stat-line {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-line-s {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #edededa5;
  padding: 1rem 0 3rem 0;
}

.stat-h {
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.stat-line-dtl {
  width: 95%;
  display: flex;
  gap: 1rem;
  height: 20vh;
}

.stat-line-h {
  width: 48%;
  display: flex;
  gap: 1rem;
  height: 20vh;
}

.stat {
  display: flex;
  width: 100%;
  height: 20vh;
  background-color: var(--dark);
  font-size: 2rem;
  flex-direction: column;
  border-radius: 0.5rem;
}

.st-hd {
  height: 25%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
}

.st-val {
  height: 75%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: bold;
}

.ch-hd {
  display: flex;
  /* padding: 1rem 0rem; */
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  /* border: 1px solid red; */
}

.ch-ch {
  flex-grow: 1;
  width: 100%;
  height: 90%;
}

.ch-selection {
  margin-right: 1rem;
}

.ch-selection > select {
  outline-color: black;
  outline-width: 0.1rem;
  outline-style: solid;
  font-size: 1.4rem;
}

.ch-selection-r {
  font-size: 1.8rem;
}
