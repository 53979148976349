.f-audit-table {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  flex-direction: column;
}

.audit-scroll-container {
  display: flex;
  width: 90%;
  min-height: 20vh;
  max-height: 65vh;
  align-items: center;
  margin-top: 1rem;
  flex-direction: column;
  gap: 3rem;
  overflow-x: visible;
  overflow-y: auto;
}

.pagination-ctrl {
  display: flex;
  margin-top: 4rem;
  width: 90%;
}

.pages {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.1rem;
}

.page {
  padding: 1rem 1.2rem;
  border: 1px solid;
  font-size: 1.3rem;
  cursor: pointer;
  font-weight: bold;
}

.selected-page {
  padding: 1rem 1.2rem;
  border: 1px solid;
  font-size: 1.3rem;
  cursor: pointer;
  background-color: var(--dark);
  color: var(--white);
  font-weight: bold;
}

.audit-container {
  display: flex;
  width: 95%;
  padding: 2rem 0rem;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
  flex-direction: column;
  gap: 3rem;
  font-size: 1.4rem;
  border-left: 1px solid;
}

.audit-strip {
  display: flex;
  align-items: center;
  width: calc(100% - 2rem);
  height: 5rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  gap: 1rem;
  margin-left: -2.5rem;
  z-index: 1;
  position: relative;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.466);
}

.a-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  color: var(--white);
  font-size: 1.8rem;
}

.a-adt-dt {
  position: absolute;
  top: 57%;
  right: 1%;
  font-style: italic;
  color: var(--dark);
  font-size: 1.4rem;
}
