.dashboard-container {
  display: flex;
  height: 92vh;
  background-color: var(--white);
  overflow-y: auto;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  /* padding-top: 2rem; */
  /* padding-left: 3rem; */
  align-content: flex-start;
  justify-content: center;
  /* margin-bottom: 3rem; */
}

.d-table {
  display: flex;
  width: 98%;
  flex-direction: column;
  background-color: var(--accent);
  padding-bottom: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.466);
}

.run-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.d-head {
  display: flex;
  gap: 2.5rem;
}

.d-header {
  /* background-color: black; */
  /* margin: rem rem 1rem 1rem; */
  padding: 0rem 3rem;
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  /* width: 98%; */
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.466);
  z-index: 10;
  color: var(--main);
  font-size: 1.8rem;
  font-weight: bold;
}

.d-data {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  overflow: auto;
  min-height: 10vh;
  max-height: calc(92vh - 18rem);
}

.d-area {
  height: 4rem;
  background-color: var(--main);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 2rem;
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
}

.d-30 {
  width: 30%;
  /* display: flex; */
}

.d-20 {
  width: 20%;
  text-align: left;
}

.d-10 {
  width: 10%;
  text-align: center;
}

.batch {
  background-color: white;
  color: var(--main);
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
}

.product {
  background-color: var(--black);
  color: var(--white);
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  font-weight: bold;
}

.d-line {
  display: flex;
  width: 100%;
  font-size: 1.4rem;
  padding: 0.5rem 0rem;
}

.d-13 {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-60 {
  width: 58%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  row-gap: 0.8rem;
}

.d-5 {
  display: flex;
  width: 5%;
  justify-content: center;
  align-items: center;
}

.even {
  background-color: var(--white);
}

.odd {
  background-color: var(--grey);
}

.d-user {
  background-color: white;
  /* height: 100%; */
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.466);
}

.d-user-name {
  padding: 0.5rem 1rem;
  background-color: var(--green);
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.466);
  z-index: 10;
  font-size: 1.4rem;
}

.d-user-blank {
  padding: 0.5rem 1rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.466);
  z-index: 10;
  font-size: 1.4rem;
}

.d-user-name-yellow {
  padding: 0.5rem 1rem;
  background-color: rgb(255, 255, 120);
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.466);
  z-index: 10;
  font-size: 1.4rem;
}

.d-user-name-red {
  padding: 0.5rem 1rem;
  background-color: var(--red);
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.466);
  color: white;
  z-index: 10;
  font-size: 1.4rem;
}

.d-user-btn {
  background-color: white;
  /* height: 100%; */
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open-btn {
  cursor: pointer;
  font-size: 2rem;
  color: var(--main);
}

.add-btn {
  color: var(--green);
  font-size: 2.4rem;
  padding: 0;
  cursor: pointer;
}

.bad-add-btn {
  color: var(--red);
  font-size: 2.4rem;
  padding: 0;
  cursor: pointer;
}

.new-esign-input {
  width: 100%;
  margin: 1rem 1rem 0rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark);
}

.new-esign-label {
  width: 50%;
  font-family: "Poppins";
  font-size: 1.6rem;
  font-weight: bold;
  text-align: right;
  margin-right: 2rem;
  display: flex;
  justify-content: flex-start;
}

.create-job-header {
  width: 100%;
}

.allocate-header {
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.flex-row-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem 0rem 2rem;
}

.avail-users-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  overflow-y: auto;
  background-color: var(--grey);
  max-height: 40%;
  min-height: 40%;
  gap: 1rem;
}

.close-flex {
  display: none !important;
}

.margin-btm {
  margin-bottom: 3rem;
}

.new-job-icon {
  font-size: 2.8rem;
  color: var(--red);
  margin-right: 1rem;
}
.new-job-head {
  text-align: center;
  font-size: 1.6rem;
  color: var(--dark);
  font-family: "Poppins";
  font-weight: bold;
}

.new-job-input {
  width: 100%;
  margin: 3rem 2rem 1rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark);
}

.new-job-label {
  width: 50%;
  font-family: "Poppins";
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 2rem;
  text-align: right;
  margin-right: 2rem;
}

.new-job-ta {
  width: 100%;
  text-align: left;
}
.btn-save {
  background-color: #00cc83;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-family: "Poppins";
  border-radius: 1rem;
  margin: 2rem 1rem 2rem 1rem;
  cursor: pointer;
  color: white;
  font-size: 1.4rem;
}

.btn-cancel {
  background-color: #ff5f5f;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-family: "Poppins";
  border-radius: 1rem;
  margin: 2rem 1rem 2rem 1rem;
  cursor: pointer;
  color: white;
  font-size: 1.4rem;
}

.disable {
  pointer-events: none;
}

.esign-modal {
  position: fixed;
  top: 42%;
  left: 40%;
  width: 20%;
  height: 16%;
  background-color: var(--white);
  border-style: solid;
  border-color: var(--main);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  border-radius: 2rem;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 2rem;
}

.allocate-modal {
  position: fixed;
  top: 30%;
  left: 30%;
  width: 40%;
  height: 40%;
  background-color: var(--white);
  border-style: solid;
  border-color: var(--main);
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  z-index: 100;
  border-radius: 2rem;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 2rem;
}

.pp-modal {
  position: fixed;
  top: 30%;
  left: 30%;
  width: 40%;
  background-color: var(--white);
  border-style: solid;
  border-color: var(--main);
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  z-index: 100;
  border-radius: 2rem;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 2rem;
}

.MuiSvgIcon-root {
  font-size: 2rem !important;
}

.MuiTablePagination-root {
  font-size: 1.4rem !important;
}

.MuiTablePagination-root > * {
  font-size: 1.4rem !important;
}

.MuiBox-root > label {
  font-size: 1.4rem;
}

.MuiBox-root > div {
  font-size: 1.4rem;
  text-align: center;
}
